@use './scss/' as *;

/**
  * Name: ICOLand | ICO landing page & ICO Crypto HTML Template
  * Version: 1.0.2
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'resobot-bold';
  src: url('./assets/fonts/GeneralSans-Bold.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html {
  margin-right: 0 !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
div#root {
  overflow: hidden;
}

body {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: $txt-color;
  background: #09121d;

  .home2 {
    .bg_body {
      position: relative;
      .bg_h2 {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        img {
          height: 100%;
          opacity: 0.5;
          background: linear-gradient(
            180deg,
            #09121d 3.92%,
            rgba(9, 18, 29, 0) 36.79%,
            #09121d 83.98%
          );
          mix-blend-mode: luminosity;
        }
      }
    }
  }
}

p {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: $txt-color;
}

img {
  max-width: 100%;
  height: auto;
  transform: scale(1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'resobot-bold';
  font-weight: 700;
  color: $white;
}
h1,
.h1 {
  font-size: 72px;
  line-height: 1;

  @include res767 {
    font-size: 40px;
  }
}
h2,
.h2 {
  font-size: 50px;
  line-height: 1.1;
}
h3,
.h3 {
  font-size: 40px;
  line-height: 1.1;
  z-index: 999;
  position: relative;
}
h4,
.h4 {
  font-size: 28px;
  line-height: 1.4;
}
h5,
.h5 {
  font-size: 26px;
  line-height: 1.4;
}
h6,
.h6 {
  font-size: 16px;
  line-height: 1.72;
}

.container {
  max-width: 1140px;
  &.w_1690 {
    max-width: 1690px;
    .container_inner {
      position: relative;
      padding: 0 72px;
    }
  }
  &.full {
    max-width: 100%;
  }
  &.w_1280 {
    max-width: 1280px;
    .container_inner {
      position: relative;
      padding: 0 70px;

      @include res767 {
        padding: 0 15px;
      }
    }
  }
  &.w_1490 {
    max-width: 1490px;
  }
  &.w_1342 {
    max-width: 1342px;
  }
  &.w_1710 {
    max-width: 1920px;
    padding-left: 120px;
    padding-right: 120px;
  }
  &.w_1850 {
    max-width: 1850px;
    .container_inner {
      position: relative;
      padding: 0 94px;
    }
  }
  &.w_1320 {
    max-width: 1320px;
    .container_inner {
      position: relative;
      padding: 0 90px;

      @include res767 {
        padding: 0 15px;
      }
    }
  }
  &.w_1310 {
    max-width: 1310px;
    .container_inner {
      position: relative;
      padding: 0 84px;
    }
  }
}

.home2 .project2 {
  .container {
    &.w_1280 {
      max-width: 1280px;
      .container_inner {
        position: relative;
        padding: 0 70px;

        @include res767 {
          padding: 0 15px;
        }
      }
    }
  }
}

.text {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: #798da3;
}

.z-index {
  z-index: 999;
}

#wrapper {
  width: $w100;
  height: $w100;
  overflow: hidden;
  position: relative;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}
ul,
li {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
  transition: all 0.3s;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $color-hover;
    transition: all 0.3s;
  }
}

.center {
  text-align: center;
}

.container {
  width: 100%;
  margin: auto;
}
.container-fluid,
.container {
  padding-left: 15px;
  padding-right: 15px;
}

.wrap-fx-30 {
  @include flex2;
  margin-left: -30px;
  .icon-box {
    width: calc(33.3333% - 30px);
    margin-left: 30px;
  }
}
.no-pd-top {
  padding-top: 0;
}

.m-b17 {
  margin-bottom: 17px;
}

.p-b42 {
  padding-bottom: 42px;
}
.m-b40 {
  margin-bottom: 40px;
}
.m-r-40 {
  margin-right: -40px;
}

.p-l150 {
  padding-left: 150px;
}

.m-t30 {
  margin-top: 30px;
}

.p-l70 {
  padding-left: 70px;
}

.mt-48 {
  margin-top: 48px;
}

.ml30-fx {
  margin-left: -30px;
}

.mb-0 {
  margin-bottom: 0;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b51 {
  margin-bottom: 51px;
}

.p-r60 {
  padding-right: 60%;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b61 {
  margin-bottom: 61px;
}

.m-b26 {
  margin-bottom: 26px;
}

.m-b6 {
  margin-bottom: 6px;
}

.m-t12 {
  margin-top: 12px;
}

.m-b16 {
  margin-bottom: 16px;
}
.m-t16 {
  margin-top: 16px;
}
.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.pl-r-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-b23 {
  padding-bottom: 23px;
}

.boder-bt-st1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.txt-tranf-cap {
  text-transform: capitalize;
}

.m-t60 {
  margin-top: 60px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b48 {
  margin-bottom: 48px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.pd20 {
  padding: 0 20%;
}

.pd22 {
  padding: 0 22%;
}

.m-b51 {
  margin-bottom: 51px;
}

.pd30 {
  padding: 0 30%;
}

.bg-st1 {
  background: $bg-3 !important;
}

.bg-st2 {
  background: $bg-main !important;
}

.wrap-fx {
  @include flex2;
  flex-wrap: wrap;
}

.wrap-fx2 {
  @include flex(center, center);
  position: relative;
}
select {
  background-color: $bg-2;
  border: none;
  font-family: $font-body;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  line-height: 21px;
  border-radius: 0;
  height: 46px;
  width: 100%;
  color: $txt-color;
  padding: 12px 14px;
  border-radius: 8px;
  @include flex(center, flex-start);
}
// form //
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  border: none;
  font-family: $font-body;
  outline: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  line-height: 21px;
  border-radius: 0;
  height: 46px;
  width: 100%;
  background: $bg-3;
  color: $txt-color;
  padding: 12px 14px;
  border-radius: 8px;
  @include flex(center, flex-start);
}

textarea::placeholder,
input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder,
input[type='datetime']::placeholder,
input[type='datetime-local']::placeholder,
input[type='date']::placeholder,
input[type='month']::placeholder,
input[type='time']::placeholder,
input[type='week']::placeholder,
input[type='number']::placeholder,
input[type='email']::placeholder,
input[type='url']::placeholder,
input[type='search']::placeholder,
input[type='tel']::placeholder,
input[type='color']::placeholder {
  font-family: $font-body;
  color: $txt-color;
}

form {
  button {
    border: none;
    outline: none;
  }
}

.form-seach {
  position: relative;
  @include flex(center, flex-start);
  input {
    padding-left: 48px;
  }
  svg {
    position: absolute;
    left: 17px;
  }
}

/* Scroll Top
-------------------------------------------------------------- */
#scroll-top {
  position: fixed;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  text-align: center;
  z-index: 999;
  right: 14px;
  bottom: 23px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
}

#scroll-top.show {
  right: 15px;
  opacity: 1;
  visibility: visible;
}

#scroll-top:before,
#scroll-top:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include transition3;
}

#scroll-top:before {
  z-index: -1;
  background: #ec6707;
}

#scroll-top:after {
  content: '\f077';
  font-family: 'Font Awesome 5 Pro';
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

#scroll-top:hover {
  transform: translateY(-7%);
}

/* PreLoad
-------------------------------------------------------------- */
.preloader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.clear-loading {
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.loading-effect-2 {
  width: 100px;
  height: 100px;
}
.loading-effect-2 > span,
.loading-effect-2 > span:before,
.loading-effect-2 > span:after {
  content: '';
  display: block;
  border-radius: 50%;
  border: 2px solid $color-hover;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading-effect-2 > span {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-left-color: transparent;
  -webkit-animation: effect-2 2s infinite linear;
  -moz-animation: effect-2 2s infinite linear;
  -ms-animation: effect-2 2s infinite linear;
  -o-animation: effect-2 2s infinite linear;
  animation: effect-2 2s infinite linear;
}
.loading-effect-2 > span:before {
  width: 75%;
  height: 75%;
  border-right-color: transparent;
}
.loading-effect-2 > span:after {
  width: 50%;
  height: 50%;
  border-bottom-color: transparent;
}
@-webkit-keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes effect-2 {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.qrcode {
  > img {
    padding-top: 20px !important;
  }
}
